/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    func, shape, arrayOf, string, bool, number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
// tracking
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

// helpers
import cleanText from '../helpers/cleanText';
import parseFont from '../helpers/parseFontFamily';
import openInNewTab from '../../../../../helpers/contentstack/openInNewTab';

// partials/components
import CustomIcon from '../../../../ContentTypeComponents/CustomIcons';

const hoverStyles = (styling) => {
    if (styling.hover_styles?.dropdown_links === 'button') {
        return (
            {
                color: styling?.colors?.drop_down_link_hover?.color || '#777',
                borderColor: styling?.colors?.bg_drop_down_link?.color,
                background: styling?.colors?.bg_drop_down_link?.color,
            }
        );
    }
    if (styling.hover_styles?.dropdown_links === 'underline') {
        return (
            {
                color: styling?.colors?.drop_down_link_hover?.color || '#777',
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textUnderlineOffset: '3px',
            }
        );
    }
    return {};
};

const useStyles = makeStyles(() => ({
    column: {
        listStyle: 'none',
        display: 'flex',
        margin: '0 2em 0 0',
        userSelect: 'none',
        width: '100%',
        maxWidth: '300px',
        textAlign: 'left',
        '& ul': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        '&>ul>li+li': {
            marginTop: '1.3em',
        },
        '&>a': {
            textDecoration: 'none',
        },
        '& .customIcon': {
            marginLeft: '-.55em',
        },
    },
    category: (styling) => ({
        display: 'block',
        '&>a': {
            display: 'flex',
            justifyContent: 'start',
            color: styling?.colors?.drop_down_link?.color || '#1f1f1b',
            fontFamily: `Area Variable, ${parseFont(styling?.fonts?.category)}`,
            fontSize: `clamp(0.75rem, 1vw + .3rem, ${styling?.fonts?.font_sizes?.dd_main_level || 1}rem)`,
            textDecoration: 'none',
            textDecorationColor: styling?.colors?.drop_down_link?.color || '#1f1f1b',
            transition: 'all .2s',
            '&:hover': {
                color: styling?.colors?.drop_down_link_hover?.color || '#777',
                textDecoration: 'underline',
            },
        },
        '&>div': {
            color: styling?.colors?.drop_down_link?.color || '#1f1f1b',
            fontFamily: `Area Variable, ${parseFont(styling?.fonts?.category)}`,
            fontSize: `clamp(0.75rem, 1vw + .3rem,${styling?.fonts?.font_sizes?.dd_main_level || 1}rem)`,
            textDecoration: 'none',
        },
    }),
    pronounced: (styling) => ({
        '&>*': {
            fontSize: `clamp(0.78rem, 1vw + .5rem, .3rem + ${styling?.fonts?.font_sizes?.dd_main_level || 1}rem)`,
            lineHeight: '1em',
        },
    }),
    subCategory: (styling) => ({
        display: 'block',
        paddingTop: 'clamp(.2em, 1vw .35em)',
        paddingBottom: 'clamp(.2em, 1vw .35em)',
        marginLeft: '0',
        '&>a': {
            display: 'flex',
            justifyContent: 'start',
            color: styling?.colors?.drop_down_link?.color || '#1f1f1b',
            fontFamily: `Area Variable, ${parseFont(styling?.fonts?.subcategory)}`,
            fontSize: `clamp(0.8rem, 1vw + .1rem,${styling?.fonts?.font_sizes?.dd_second_level || 0.95}rem)`,
            textDecoration: 'none',
            border: `solid 1px ${styling?.colors?.bg_drop_down?.color}`,
            borderWidth: '.3rem .4rem',
            borderRadius: '4px',
            background: styling?.colors?.bg_drop_down?.color,
            transition: 'all .2s ease-out',
            '&:hover': hoverStyles(styling),
        },
        '& .customIcon': {
            margin: '0 .3rem 0 -1.3rem',
        },
    }),

}));

const hasIcon = (icon) => icon?.svg_data && <CustomIcon data={icon} />;

// Finally, render the subcategories associated with each category (first step down below)
const buildSubCategory = (subCategories, styling, closeDropdown, groupTitle = '', trackExperiment) => {
    const dispatch = useDispatch();
    const classes = useStyles(styling);
    const seed = useUIDSeed();
    const close = () => closeDropdown();
    if (!subCategories?.length) return <></>;
    return (
        subCategories?.map((subCategory, item) => (
            !subCategory?.hide_entry && (
                <li role="presentation" className={classes.subCategory} data-testid={subCategory?.entry_title} key={seed(subCategory?.entry_title + item)}>
                    <Link
                        to={{ pathname: subCategory?.linking?.link?.href || '#missing-href-from-cms' }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...openInNewTab(subCategory?.linking?.link?.href)}
                        id={`SubCategoryLink_${subCategory?.heading?.linking?.tracking_event_label || seed(item) }_${item}`}
                        role="menuitem"
                        onClick={() => {
                            close();
                            let origin = '';
                            if (typeof window !== 'undefined' && window.location && window.location.origin) {
                                origin = window.location.origin;
                            }
                            trackExperiment({
                                link: `${origin}${subCategory?.linking?.link?.href}`,
                                label: `${groupTitle} - ${subCategory?.linking?.link?.title}`,
                            });
                            dispatch(trackEvent({
                                eventCategory: subCategory?.linking?.tracking_event_category || '',
                                eventAction: subCategory?.linking?.tracking_event_action || '',
                                eventLabel: subCategory?.linking?.tracking_event_label || '',
                                header_section: 'Drop Down Link',
                                link_url: `${origin}${subCategory?.linking?.link?.href}`,
                                link_text: subCategory?.linking?.link?.title,
                                link_parent: groupTitle,
                            }));
                        }}
                    >
                        {hasIcon(subCategory.icon)}
                        {
                            (subCategory?.display_text?.length && cleanText(subCategory?.display_text))
                            || (subCategory?.entry_title?.length && subCategory?.entry_title)
                            || 'Missing Entry Name'
                        }
                    </Link>
                </li>
            )
        ))
    );
};

// Next, create a Category with a heading, stacking them if defined in the CMS
const buildCategory = (groupTitle, categories, styling, closeDropdown, trackExperiment) => {
    const dispatch = useDispatch();
    const classes = useStyles(styling);
    const seed = useUIDSeed();
    const close = () => closeDropdown();
    return (
        categories?.map((category, item) => (
            !category.hide_collection && (
                <li role="presentation" className={`${classes.category}${category.heading?.pronounced_heading ? classes.pronounced : ''}`} key={seed(item)}>
                    {/* Display as text if no href */}
                    {category?.heading?.linking?.link?.href ? (
                        <Link
                            to={{ pathname: category?.heading?.linking?.link?.href || '#missing-href-from-cms' }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...openInNewTab(category?.linking?.link?.href)}
                            id={`CategoryLink_${groupTitle}_${category?.heading?.linking?.tracking_event_label || seed(item) }_${item}`}
                            role="menuitem"
                            onClick={() => {
                                close();
                                let origin = '';
                                if (typeof window !== 'undefined' && window.location && window.location.origin) {
                                    origin = window.location.origin;
                                }
                                trackExperiment({
                                    link: `${origin}${category?.heading?.linking?.link?.href}`,
                                    label: `${groupTitle} - ${category?.heading?.linking?.link?.title}`,
                                });
                                dispatch(trackEvent({
                                    eventCategory: category?.heading?.linking?.tracking_event_category || '',
                                    eventAction: category?.heading?.linking?.tracking_event_action || '',
                                    eventLabel: category?.heading?.linking?.tracking_event_label || '',
                                    header_section: 'Drop Down Link',
                                    link_url: `${origin}${category?.heading?.linking?.link?.href}`,
                                    link_text: category?.heading?.linking?.link?.title,
                                    link_parent: groupTitle,
                                }));
                            }}
                        >
                            {hasIcon(category?.heading.icon)}
                            {
                                (category?.display_text?.length && cleanText(category?.display_text))
                            ||  (category?.entry_title?.length && category?.entry_title)
                            ||  'Missing Entry Name'
                            }
                        </Link>
                    ) : (
                        <div
                            id={`CategoryLink_${groupTitle}_${category?.heading?.linking?.tracking_event_label || seed(item) }_${item}`.split(' ').join('_')}
                            className={category.heading?.pronounced_heading ? classes.pronounced : ''}
                        >
                            {hasIcon(category.icon)}
                            {
                                (category?.display_text?.length && cleanText(category?.display_text))
                            ||  (category?.entry_title?.length && category?.entry_title)
                            ||  ReactHtmlParser('&nbsp;')
                            }
                        </div>
                    )}
                    <ul role="menu">
                        {buildSubCategory(category?.entry, styling, closeDropdown, groupTitle, trackExperiment)}
                    </ul>
                </li>
            )
        ))
    );
};

// First, build out each column defined in CMS
const buildColumns = (groupTitle, data, styling, closeDropdown, trackExperiment) => {
    const classes = useStyles(styling);
    const seed = useUIDSeed();
    const columns = data?.[0]?.columns || [];
    return (
        columns.length > 0  ? columns.map((element, item) => (
            <li className={classes.column} key={seed(item)}>
                <ul>
                    {buildCategory(groupTitle, element?.collections, styling, closeDropdown, trackExperiment)}
                </ul>
            </li>
        )) : null
    );
};

const LinkGroup = ({
    groupTitle, data, styling, closeDropdown, trackExperiment,
}) => buildColumns(groupTitle, data, styling, closeDropdown, trackExperiment);

LinkGroup.defaultProps = {
    trackEvent: () => {},
};

LinkGroup.propTypes = {
    data: arrayOf(shape({
        collections: arrayOf(shape({
            entry: shape({
                display_text: string,
                entry_title: string,
                hide_entry: bool,
                linking: shape({
                    tracking_event_category: string,
                    tracking_event_action: string,
                    tracking_event_label: string,
                }),
            }),
        })),
    })).isRequired,
    styling: shape({
        fonts: shape({
            department: string,
            category: string,
            subcategory: string,
            subsubcategory: string,
            promoted: string,
            font_sizes: shape({
                main_level: number,
                dd_main_level: number,
                dd_second_level: number,
                drop_down_third_level: number,
                dd_promo: number,
            }),
        }),
        colors: shape({
            bg_nav: shape({ color: string }),
            main_link: shape({ color: string }),
            main_link_hover: shape({ color: string }),
            bg_main_link_hover: shape({ color: string }),
            bg_drop_down: shape({ color: string }),
            drop_down_link: shape({ color: string }),
            drop_down_link_hover: shape({ color: string }),
            bg_drop_down_link: shape({ color: string }),
        }),
    }).isRequired,
    closeDropdown: func.isRequired,
    trackEvent: func,
    trackExperiment: func.isRequired,
};

export default LinkGroup;
